import logo from './logo.svg';
import React, { useState } from 'react';
import './App.css';
const users = {
   XMLSJD2394092:"majesticwoodfloorsnycom",
   may:"maycleaningcom",
   shark:"sharknewyorkcom",
   quinta:"quintasteakhousecom",
   agvipautodetailing:"agvipautodetailingcom",
   
};

class App extends React.Component {
  constructor(props) {
    super(props);
    const loc = window.location.pathname;
    const name = loc.replace("/","").replace("/","")
    let username = users[name];
    if(!username){
      username = null;
    }
    this.state = { store:{},username:username};
  }
  swap(json){
    var ret = {};
    for(var key in json){
      ret[json[key]] = key;
    }
    return ret;
  }
  selectUser(user){
    this.setState({username:user, store:null});
    this.fetchMessages(user);
  }
  fetchMessages(user){
    fetch(`https://weyoopos.firebaseio.com/inbox/${user}/messages.json`)
    .then(response => response.json())
    .then(data =>this.cacheData(data));
  }
  componentDidMount() {
    this.fetchMessages(this.state.username);
  }
 
  cacheData(data){
    let Data  = [];
    if(typeof data ==="object"){
      let i=  Object.keys(data).length;
      Object.keys(data).map(key => {   
        Data[i]=data[key];
        i--;
      });
  
    }
    this.setState({store:Data});
  }
  render() {
    let c=0;
    if(this.state.username === null){
        return (<div> NOT FOUND </div>)
    }else{
        return (
            <div className="section">
              <div className="clmn box">
                <div className="total">Messages {this.state.username} ({this.state.store!== null ? Object.keys(this.state.store).length:"0"})</div>
                {this.state.store!== null ? Object.keys(this.state.store).map(key => {   
                  const className = c===0? "item first-item":"item";
                  c++;
                  return <div className={className} key={c} >
                      <p className="msg_name">{this.state.store[key].item1}</p>
                      <p>{this.state.store[key].item2}</p>
                      <p>{this.state.store[key].item3}</p>
                      <p>{this.state.store[key].item4}</p>
                      <p>{this.state.store[key].item5}</p>
                      <p>{this.state.store[key].item6}</p>
                      <p className="msg_date">{this.state.store[key].datetime}</p>
                  </div>
                  
                }):""}
                </div>
            </div>
        
        );
      }
  }
}

export default App;
